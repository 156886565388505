<template>
	<div class="add-truck">
		<Header></Header>
		<div class="wrapper" style="min-height: 738px;">
			<div class="form-wrapper shadow">
				<div class="main-title">新建车辆</div>
				<el-steps v-if="ruleForm.mplanNew == 1" :active="ruleForm.mplanStep" align-center
					finish-status="success" style="margin:40px 0;">
					<el-step title="添加车辆" :description="ruleForm.mplanInsuranceRemark"></el-step>
					<el-step title="加装设备" :description="ruleForm.mplanAddDeviceRemark"></el-step>
					<el-step title="用户提车" :description="ruleForm.mplanSentRemark"></el-step>
				</el-steps>
				<el-steps v-if="ruleForm.mplanNew == 0" :active="ruleForm.mplanStep" align-center
					finish-status="success" style="margin:40px 0;">
					<el-step title="添加车辆" :description="ruleForm.remark"></el-step>
					<el-step title="车辆检测" :description="ruleForm.mplanCheckRemark"></el-step>
					<el-step title="加装设备" :description="ruleForm.mplanAddDeviceRemark"></el-step>
					<el-step title="用户提车" :description="ruleForm.mplanSentRemark"></el-step>
				</el-steps>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
					<el-form-item label="所属车队" prop="fleetId">
						<el-select v-model="ruleForm.fleetId" placeholder="请选择车队">
							<el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
						<el-button v-if="ruleForm.id == 0 || stepEdit" type="primary" @click="handleAddFleet">新增车队</el-button>
					</el-form-item>
					<el-form-item label="车牌号" prop="number">
						<el-input v-model="ruleForm.number"></el-input>
					</el-form-item>
					<el-form-item label="底牌号" prop="vin">
						<el-input v-model="ruleForm.vin" placeholder="请填写底盘号后8位"></el-input>
					</el-form-item>
					<el-form-item label="品牌型号" prop="brand">
						<el-input v-model="ruleForm.brand" />
					</el-form-item>
					<el-form-item label="车主姓名" prop="owner">
						<el-input v-model="ruleForm.owner"></el-input>
					</el-form-item>
					<el-form-item label="车主电话" prop="phone">
						<el-input v-model="ruleForm.phone"></el-input>
					</el-form-item>
					<el-form-item label="车辆类型" prop="mplanNew">
						<el-radio-group v-model="ruleForm.mplanNew">
							<el-radio :label="1">新车</el-radio>
							<el-radio :label="0">运营中车辆</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="行驶里程" prop="mileage">
						<el-col :span="8">
							<el-input v-model="ruleForm.mileage" placeholder="请填写当前行驶里程"></el-input>
						</el-col>
						<el-col class="line" style="margin-left: 10px;" :span="2">KM</el-col>
					</el-form-item>
					<el-form-item label="是否太平洋保险" prop="mplanCPIC">
						<el-radio-group v-model="ruleForm.mplanCPIC">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="ruleForm.mplanCPIC" label="保险到期日期" prop="insuranceDate">
						<el-date-picker v-model="ruleForm.insuranceDate" :picker-options="startDateDisabled" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="审车到期日期" prop="inspectionDate">
						<el-date-picker v-model="ruleForm.inspectionDate" :picker-options="startDateDisabled"
							type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
                    <el-form-item label="养护包内容" prop="mplanContent">
                        <el-input v-model="ruleForm.mplanContent"></el-input>
                    </el-form-item>
					<el-form-item label="备注" prop="mplanInsuranceRemark">
						<el-input v-model="ruleForm.mplanInsuranceRemark"></el-input>
					</el-form-item>
					<el-form-item v-if="ruleForm.id == 0 || stepEdit" class="form-btn">
						<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<Footer></Footer>

		<el-dialog title="新增车队" :visible.sync="dialogFleetShow">
			<el-form ref="addFleet" :rules="fleetRules" :model="fleet" label-position="left" label-width="150px"
				style="width: 400px; margin-left:50px;">
				<el-form-item label="名称" prop="name">
					<el-input v-model="fleet.name" placeholder="请输入车队名称" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFleetShow = false">
					取消
				</el-button>
				<el-button type="primary" @click="submitAddFleet">
					提交
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				stepEdit: false,
				startDateDisabled: {},
				dialogFleetShow: false,
				fleet: {
					name: ''
				},
				fleetList: [],
				ruleForm: {
					id: 0,
					fleetId: '',
					number: '',
					vin: '',
					brand: '',
					mplanNew: 0,
					owner: '',
					phone: '',
					mileage: '',
					mplanCPIC: 0,
					insuranceDate: '',
					inspectionDate: '',
					mplanInsuranceRemark: '',
                    mplanContent: ''
				},
				rules: {
					vin: [{
						required: true,
						message: "请填写底盘号后8位",
						trigger: "blur"
					}],
					owner: [{
						required: true,
						message: "请填写车主姓名",
						trigger: "blur"
					}],
					phone: [{
							required: true,
							message: '请输入车主手机号',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号格式不对',
							trigger: 'change'
						}
					],
					mplanNew: [{
						required: true,
						message: '请选择车辆类型',
						trigger: 'change'
					}],
					mplanCPIC: [{
						required: true,
						message: '请选择保险公司',
						trigger: 'change'
					}],
					inspectionDate: [{
						required: true,
						message: '请填写审车到期日期',
						trigger: 'change'
					}],
                    mplanContent: [{
                        required: true,
                        message: '请填写养护包内容',
                        trigger: 'blur'
                    }]
				},
				fleetRules: {
					name: [{
						required: true,
						message: "请填写车队名称",
						trigger: "blur"
					}],
				}
			}
		},
		created() {
			let roles = localStorage.getItem('roles').split(',')
			if(roles.indexOf('mplan-edit') > -1){
				this.stepEdit = true
			}
			this.startDateDisabled.disabledDate = function(time) {
				return (time.getTime() + 24 * 3600 * 1000) < Date.now()
			}

			if (this.$route.query.id) {
				this.ruleForm.id = this.$route.query.id
				this.getCar()
			}
			this.getFleetList()
		},
		methods: {
			getFleetList() {
				http.fetchGet('/api/admin/fleet/list', {
					page: 1,
					pagesize: 1000
				}).then((res) => {
					if (res.data.code == 0) {
						this.fleetList = res.data.data.list
					}
				})
			},
			getCar() {
				http.fetchGet('/api/admin/car', {
					id: this.ruleForm.id
				}).then((res) => {
					if (res.data.code == 0) {
						this.ruleForm = res.data.data
						if (this.ruleForm.fleet) {
							this.ruleForm.fleetId = this.ruleForm.fleet.id
						}
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			submitForm(formName) {
				let app = this
				let tip = this.ruleForm.id == 0
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('请确认信息正确无误，提交后无法修改，是否提示?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							http.fetchPost('/api/admin/mplan/car', app.ruleForm).then((res) => {
								if (res.data.code == 0) {
									app.ruleForm.id = res.data.data.id
									app.getCar()
                                    if (tip) {
                                        let msg = ""
                                        if (app.ruleForm.mplanCPIC == 1) {
                                            if (app.ruleForm.mplanNew == 1) {
                                                msg = "保险办理已完成，请到服务站加装设备"
                                            } else {
                                                msg = "保险办理已完成，请到服务站进行车辆检测"
                                            }
                                        } else {
                                            if (app.ruleForm.mplanNew == 1) {
                                                msg = "合同办理已完成"
                                            }
                                        }
                                        app.$alert(msg, '办理完成', {
                                            confirmButtonText: '确定',
                                            callback: action => {
                                                console.log(action)
                                                app.$router.push("/home")
                                            }
                                        })

                                    } else {
                                        app.$message.success('保存成功')
                                    }
								} else {
                                    app.$message.error(res.data.desc)
                                }
							})
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			handleAddFleet() {
				this.fleet.name = ''
				this.dialogFleetShow = true
			},
			submitAddFleet() {
				this.$refs['addFleet'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/fleet', this.fleet).then((res) => {
							if (res.data.code == 0) {
								this.dialogFleetShow = false
								this.getFleetList()
								this.ruleForm.fleetId = res.data.data.id
								this.$message.success('添加成功')
								// this.$router.push("/carId")
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				});
			}
		}
	}
</script>
<style lang="scss" scoped>

</style>
